import Vue from "vue";
import Component from "vue-class-component";

@Component
export class HasEnumMethods extends Vue {
  /**
   * Converts an enum object to an array of objects with label and value properties
   *
   * @param enumObject Any property enum object
   * @param dir The directory of the property
   *
   * @returns Array of objects with label and value properties
   */
  optionsFromEnum(enumObject: any, dir: string | null = "", useKeyAsLabel = false) {
    // @ts-ignore (enum object is always string or number)
    return Object.keys(enumObject).map((k: string | number) => {
      let kv = useKeyAsLabel ? k : enumObject[k];
      let v = enumObject[k];

      return {
        label: dir ? (this.$t(`${dir}.${kv}`) as string) : `${kv}`,
        value: v,
      };
    });
  }
}
