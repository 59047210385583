export enum RepresentativeTitle {
  Mr = "mr",
  Mrs = "mrs",
}

export enum RepresentativeMaritalState {
  Unmarried = "unmarried",
  LivingTogether = "living_together",
  LegallyLivingTogether = "leagally_living_together",
  Married = "married",
  Separated = "separated",
  Widower = "widower",
}
